.App {
  text-align: center;
  width:90%;
  margin: 0 4% 0 5%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.cardProdotto{
  width:90%;
  text-align:center;
  margin: 2%;
  padding: 1%;
  border: 1px solid #e3e3e3;
  border-radius: 12px;
  background-color: #f8f8f8;
  color: #666;
}
.cardProdottoDett{
  width:90%;
  text-align:center;
  margin: 2%;
  padding: 2%;
  border: 1px solid #e3e3e3;
  border-radius: 12px;
  background-color: #f8f8f8;
  color: #666;
}
.cardProdotto a, a:active, a:visited, a:hover {
  font-weight: 600;
  color: #e28743;
  text-decoration:none;
}
.cardProdottoDett a, a:active, a:visited, a:hover {
  font-weight: 600;
  color: #e28743;
  text-decoration:none;
}
.cardProdottoDett .copertina {
  width:100%;
  text-align:center;
  margin-bottom:2%;
}
.cardProdotto img {
  width:100%;
  border-radius: 12px;
  max-width:300px !important;
  object-fit: contain;
}
.cardProdottoDett .fotoCorso{
  max-width:300px !important;
  border-radius: 12px;
  object-fit: contain;
}
.cardProdotto h3 {
  color: #a34db4;
}
.cardProdotto p {
  text-align:justify;
  margin: 4% 0 5% 0;
}
.cardProdottoDett h5 {
  font-weight:600;
  margin: 2% 0 0 0;
}
.cardProdottoDett .dettaglio {
  text-align:justify;
}
.cardProdotto h2 {
  color: #e28743;
  font-size: 1.4rem;
  text-align: right;
  margin-top: 1%;
}
.cardProdottoDett .prezzo {
  color: #e28743;
  font-size: 1.7rem;
  text-align: right;
  margin-top: 0;
}